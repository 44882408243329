import pages from "../pages/index";

const routes = [
  // Warehouse
  {
    resource: "warehouse",
    path: "warehouse",
    element: pages.Warehouse,
    children: [
      { path: "new", element: pages.CreateNewProduct },
      { path: "stock-in", element: pages.StockIn },
      { path: ":id", element: pages.WHProductDetails },
    ],
  },
  {
    resource: "warehouse",
    path: "resupply",
    element: pages.Resupply,
  },
  {
    resource: "warehouse",
    path: "supplier",
    element: pages.Suppliers,
    children: [
      { path: "new", element: pages.CreateSupplier },
      { path: ":id", element: pages.SupplierDetails },
    ],
  },
  {
    resource: "warehouse",
    path: "orders",
    element: pages.PurchaseOrder,
    children: [
      { path: "new", element: pages.CreatePurchaseOrder },
      { path: ":id", element: pages.OrderDetails },
    ],
  },
  // Operations
  {
    resource: "operations",
    path: "customer",
    element: pages.Customer,
    children: [
      { path: "new", element: pages.CreateNewCustomer },
      { path: ":id", element: pages.CustomerDetails },
    ],
  },
  {
    resource: "operations",
    path: "vending",
    element: pages.VendingMachines,
    children: [
      { path: "new", element: pages.CreateNewVM },
      { path: "iot-devices", element: pages.ViewIotDevices },
      { path: ":id", element: pages.VMDetails },
      { path: ":id/manage", element: pages.ManageInventory },
    ],
  },
  {
    resource: "operations",
    path: "route",
    element: pages.Route,
    children: [
      { path: "route", element: pages.Route },
      { path: "create", element: pages.CreateRoute },
      { path: "edit/:id", element: pages.EditRoute },
      { path: "view/:id", element: pages.ViewMachine },
    ],
  },
  {
    resource: "operations",
    path: "subwarehouse",
    element: pages.SubWarehouse,
    children: [
      { path: "new", element: pages.CreateServiceman },
      { path: ":id", element: pages.ServicemanDetails },
      { path: ":id/inventory", element: pages.SubInventory },
      { path: ":id/password", element: pages.SubChangePassword },
    ],
  },

  {
    resource: "operations",
    path: "jobs",
    element: pages.Jobs,
    children: [{ path: ":id", element: pages.JobsDetails }],
  },
  // Sales
  {
    resource: "sales",
    path: "sales",
    element: pages.Transactions,
  },
  {
    resource: "sales",
    path: "cash",
    element: pages.Cash,
  },
  // User
  {
    resource: "user",
    path: "users",
    element: pages.Account,
    children: [
      { path: "accounts", element: pages.Account },
      { path: "accounts/create", element: pages.CreateAccount },
      { path: "accounts/edit/:id", element: pages.EditAccount },
      { path: "accounts/password/:id", element: pages.ChangePassword },
      { path: "roles", element: pages.Role },
      { path: "roles/create", element: pages.CreateRole },
      { path: "roles/manage/:id", element: pages.ManageRole },
    ],
  },
];

export default routes;
