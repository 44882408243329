import pages from "../pages/index";

const routes = [
  {
    path: "/login",
    element: pages.Login,
  },
  {
    path: "/register",
    element: pages.Register,
  },
  {
    path: "/forgotPassword",
    element: pages.ForgotPassword,
  },
];

export default routes;
