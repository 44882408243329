import axios from "axios";

// const endpoint = "https://api.achieva.cloud";
// const endpoint = "http://localhost:3008";
const endpoint = "https://api.swms.t05.sg";

const instance = axios.create({ baseURL: endpoint });

const API = (method, url, data, options) => {
  return instance({ method, url, data, withCredentials: true, ...options });
};

export default API;
