import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { AuthContext } from "./context/AuthContext";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import publicRoutes from "./routes/public";
import protectedRoutes from "./routes/protected";
import API from "./api/api";

import Layout from "./containers/Layout";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  const queryClient = new QueryClient();
  const { user, restoreSession } = useContext(AuthContext);

  useEffect(() => {
    async function checkAuth() {
      const { data } = await API("GET", "/portal/auth/status");
      restoreSession(data);
    }

    checkAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPublicRoutes = (routes) => {
    return (
      <>
        <Route index element={<Navigate to="/login" replace />} />
        {routes.map((route, i) => {
          const { path, element } = route;
          let Component = element;
          return <Route path={path} element={<Component />} key={i} />;
        })}
      </>
    );
  };

  const renderProtectedRoutes = (routes) => {
    const firstRoute = routes.filter((i) => {
      if (user) return user.Role.permissions.includes(i.resource);
      else return true;
    })[0];
    return (
      <>
        <Route
          index
          element={<Navigate to={`/app/${firstRoute.path}`} replace />}
        />
        {routes.map((route, i) => {
          const { resource, path, element, children } = route;
          let Component = element;
          if (children)
            return (
              <Route key={i} path={path}>
                <Route
                  index
                  element={
                    <ProtectedRoute
                      resource={resource}
                      authenticatedRedirect={firstRoute.path}
                    >
                      <Component />
                    </ProtectedRoute>
                  }
                />
                {children.map((subRoute, i) => {
                  const SubComponent = subRoute.element;
                  return (
                    <Route
                      key={i}
                      path={subRoute.path}
                      element={
                        <ProtectedRoute
                          resource={resource}
                          authenticatedRedirect={firstRoute.path}
                        >
                          <SubComponent />
                        </ProtectedRoute>
                      }
                    />
                  );
                })}
              </Route>
            );
          else
            return (
              <Route
                key={i}
                path={path}
                element={
                  <ProtectedRoute
                    resource={resource}
                    authenticatedRedirect={firstRoute.path}
                  >
                    <Component />
                  </ProtectedRoute>
                }
              />
            );
        })}
      </>
    );
  };

  return (
    <QueryClientProvider client={queryClient}>
      <AccessibleNavigationAnnouncer />
      <Routes>
        <Route path="/">
          {renderPublicRoutes(publicRoutes)}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Route>
        <Route path="/app" element={<Layout />}>
          {renderProtectedRoutes(protectedRoutes)}
          <Route
            path="*"
            element={
              <Navigate to={`/app/${protectedRoutes[0].path}`} replace />
            }
          />
        </Route>
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
