const routes = [
  {
    resource: "warehouse",
    icon: "BuildingStorefront",
    name: "Warehouse",
    routes: [
      {
        icon: "InboxStack",
        path: "warehouse",
        name: "Stocks",
      },
      {
        icon: "ArchiveBoxArrowDown",
        path: "resupply",
        name: "Resupply Request",
      },
      {
        icon: "ShoppingCart",
        path: "supplier",
        name: "Supplier",
      },
      {
        icon: "ClipboardDocumentList",
        path: "orders",
        name: "Purchase Order",
      },
    ],
  },
  {
    resource: "operations",
    icon: "RectangleStack",
    name: "Operations",
    routes: [
      {
        icon: "Users",
        path: "customer",
        name: "Customer",
      },
      {
        icon: "BuildingOffice",
        path: "vending",
        name: "Vending Machine",
      },
      {
        icon: "Map",
        path: "route",
        name: "Route",
      },
      {
        icon: "Truck",
        path: "subwarehouse",
        name: "Serviceman",
      },
      {
        icon: "Briefcase",
        path: "jobs",
        name: "Job Request",
      },
    ],
  },
  {
    resource: "sales",
    icon: "Banknotes",
    name: "Sales",
    routes: [
      {
        icon: "CurrencyDollar",
        path: "cash",
        name: "Cash Reconciliation",
      },
      {
        icon: "QueueList",
        path: "sales",
        name: "Transaction",
      },
    ],
  },
  {
    resource: "user",
    icon: "UserGroup",
    name: "User Management",
    routes: [
      {
        icon: "UserCircle",
        path: "users/accounts",
        name: "Account",
      },
      {
        icon: "Key",
        path: "users/roles",
        name: "Role",
      },
    ],
  },
];

export default routes;
